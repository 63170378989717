import React from 'react'
import Logo from "../../src/resources/images/logo/mainlogo.svg"
import "../style/templates/maintenance.css"

const maintenance = () => {
    return (
        <div className= "container">
            <img  src={Logo} alt="Logo" />
            <h1>Our site is down for maintenance</h1>
        </div>
    )
}

export default maintenance
